import React from 'react'
import { motion } from 'framer-motion'
import styles from './Image.module.scss'

const Image = ({ image, ...restProps }) => {
  return (
    <motion.img className={styles.img} src={image.url} alt={image.alt} {...restProps} />
  )
}

export default Image
