import React from 'react'

const hamburger = ({ color = 'black', ...rest }) => {
  return (
    <svg
      aria-hidden="true"
      data-prefix="fal"
      data-icon="bars"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      {...rest}
    >
      <path
        fill={color}
        d="M442 114H6a6 6 0 01-6-6V84a6 6 0 016-6h436a6 6 0 016 6v24a6 6 0 01-6 6zm0 160H6a6 6 0 01-6-6v-24a6 6 0 016-6h436a6 6 0 016 6v24a6 6 0 01-6 6zm0 160H6a6 6 0 01-6-6v-24a6 6 0 016-6h436a6 6 0 016 6v24a6 6 0 01-6 6z"
      />
    </svg>
  )
}

export default hamburger
