import React from 'react'
import { RichText } from 'prismic-reactjs'
import { motion } from 'framer-motion'

import styles from './Description.module.scss'

const Description = ({ description, ...restProps }) => {
  return (
    <motion.div {...restProps} className={styles.container}>
      <div className={styles.inner}>
        <RichText render={description} />
      </div>
    </motion.div>
  )
}

export default Description
