import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App/App'

import './fonts/BigCaslonRegular.otf'

import 'reset-css'
import './styles/global.scss'

ReactDOM.render(<App />, document.getElementById('root'))
