import React from 'react'
import * as icons from './icons'

const Icon = ({ name, ...rest }) => {
  if (!name) {
    console.error('Icon need a name prop')
    return null
  }

  const Component = icons[name]
  if (!Component) {
    console.error('Icon does not exist:', name)
    return null
  }

  return <Component {...rest} />
}

export default Icon
