import React from 'react'
import { RichText } from 'prismic-reactjs'
import { motion } from 'framer-motion'

import styles from './Contacts.module.scss'

const Contacts = ({ data }) => {
  if (!data) {
    return null
  }

  const { persons, contact_info } = data

  const ulVariants = {
    show: {
      transition: {
        staggerChildren: 0.07,
        delayChildren: 0.2
      }
    },
    hide: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1
      }
    }
  }

  const liVariants = {
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.3
      }
    },
    hide: {
      y: 10,
      opacity: 0,
      transition: {
        duration: 0.3
      }
    }
  }

  return (
    <motion.div
      className={styles.container}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.3
      }}
    >
      <div className={styles.inner}>
        {persons && persons.length > 0 && (
          <>
            <motion.ul variants={ulVariants} initial="hide" animate="show" exit="hide">
              {persons.map(p => (
                <motion.li variants={liVariants} key={p.email}>
                  <p>
                    <strong>{p.name}</strong>, {p.title}
                    <br />
                    {p.email} / {p.phone}
                  </p>
                </motion.li>
              ))}
              <motion.li variants={liVariants} className={styles.contactInfo}>
                <RichText render={contact_info} />
              </motion.li>
            </motion.ul>
          </>
        )}
      </div>
    </motion.div>
  )
}

export default Contacts
