import React from 'react'
import { AnimatePresence } from 'framer-motion'
import Image from '../Image/Image'
import Description from '../Description/Description'
import useMobileDetect from 'use-mobile-detect-hook'
import NavButtons from '../NavButtons/NavButtons'

import styles from './ProjectItem.module.scss'

const DRAG_THRESHOLD = 20
const isLeftOrRight = dir => dir === 'left' || dir === 'right'
const isUpOrDown = dir => dir === 'up' || dir === 'down'

const isIos = () => {
  return (
    navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform)
  )
}

const ProjectItem = ({ currentItem, navigate, dir, isMenuOpen }) => {
  const { isDesktop, isMobile, isAndroid } = useMobileDetect()

  const showNav = !isAndroid() && !isIos() && !isMobile()

  const isImage = currentItem.category === 'image'

  const variants = {
    enter: incomingDir => {
      if (isLeftOrRight(incomingDir)) {
        return {
          opacity: 0,
          x: incomingDir === 'left' ? 600 : -600
        }
      } else if (isUpOrDown(incomingDir)) {
        return {
          opacity: 0,
          y: incomingDir === 'down' ? 600 : -600
        }
      }

      return { opacity: 0 }
    },
    center: incomingDir => {
      if (isLeftOrRight(incomingDir)) {
        return {
          opacity: 1,
          x: 0
        }
      } else if (isUpOrDown(incomingDir)) {
        return {
          opacity: 1,
          y: 0
        }
      }

      return { opacity: 1 }
    },
    exit: incomingDir => {
      if (isLeftOrRight(incomingDir)) {
        return {
          opacity: 0,
          x: incomingDir === 'right' ? 600 : -600
        }
      } else if (isUpOrDown(incomingDir)) {
        return {
          opacity: 0,
          y: incomingDir === 'up' ? 600 : -600
        }
      }

      return { opacity: 0 }
    }
  }

  const handleDragEnd = (e, info) => {
    const { x, y } = info.point

    if (Math.round(y) === 0) {
      if (x <= -DRAG_THRESHOLD) {
        navigate('left')
      } else if (x >= DRAG_THRESHOLD) {
        navigate('right')
      }
    } else if (Math.round(x) === 0) {
      if (y <= -DRAG_THRESHOLD) {
        navigate('down')
      } else if (y >= DRAG_THRESHOLD) {
        navigate('up')
      }
    }
  }

  const motionProps = {
    drag: true,
    dragDirectionLock: true,
    dragConstraints: { top: 0, right: 0, bottom: 0, left: 0 },
    onDragEnd: handleDragEnd,
    variants,
    custom: dir,
    initial: 'enter',
    animate: 'center',
    exit: 'exit',
    transition: {
      duration: 0.3
    }
  }

  return (
    <div className={styles.container}>
      <AnimatePresence exitBeforeEnter custom={dir}>
        {isImage ? (
          <Image key={currentItem.url} image={currentItem} {...motionProps} />
        ) : (
          <Description description={currentItem} {...motionProps} />
        )}
      </AnimatePresence>
      {isDesktop() && showNav && <NavButtons hidden={isMenuOpen} navigate={navigate} />}
    </div>
  )
}

export default ProjectItem
