import React, { useState, useEffect } from 'react'
import usePrismic from '../../utils/hooks/usePrismic'
import ProjectItem from '../ProjectItem/ProjectItem'
import normalizeProjects from '../../utils/normalizeProjects'

const Slideshow = ({ isMenuOpen }) => {
  const [loading, fetchedProjects] = usePrismic('projects')
  const [projectIndex, setProjectIndex] = useState(0)
  const [itemIndex, setItemIndex] = useState(0)
  const [dir, setDir] = useState(null)

  useEffect(() => {
    if (fetchedProjects && fetchedProjects.body) {
      fetchedProjects.body.forEach(project =>
        project.items.forEach(item => {
          const img = new Image()
          img.src = item.image.url
        })
      )
      setProjectIndex(Math.floor(Math.random() * fetchedProjects.body.length))
    }
  }, [fetchedProjects])

  if (loading) {
    return null
  }

  const projects = normalizeProjects(fetchedProjects)
  const currentProject = projects[projectIndex]
  const currentItem = currentProject[itemIndex]

  const navigate = dir => {
    setDir(dir)

    if (dir === 'down') {
      setItemIndex(0)
      setProjectIndex(projectIndex === 0 ? projects.length - 1 : projectIndex - 1)
    } else if (dir === 'left') {
      setItemIndex(itemIndex === currentProject.length - 1 ? 0 : itemIndex + 1)
    } else if (dir === 'up') {
      setItemIndex(0)
      setProjectIndex(projectIndex === projects.length - 1 ? 0 : projectIndex + 1)
    } else {
      setItemIndex(itemIndex === 0 ? currentProject.length - 1 : itemIndex - 1)
    }
  }

  return (
    <>
      <ProjectItem
        currentItem={currentItem}
        navigate={navigate}
        dir={dir}
        isMenuOpen={isMenuOpen}
      />
    </>
  )
}

export default Slideshow
