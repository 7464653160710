import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import styles from './Header.module.scss'
import Icon from '../Icon/Icon'

const Header = ({ isMenuOpen, setIsMenuOpen }) => (
  <div className={styles.container}>
    <h1>Metropolis Arkitekter</h1>
    <div className={styles.buttons}>
      <AnimatePresence exitBeforeEnter>
        {!isMenuOpen ? (
          <motion.button
            key="1"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{
              duration: 0.15
            }}
            onClick={() => setIsMenuOpen(true)}
          >
            <span className={styles.mobile}>
              <Icon name="hamburger" />
            </span>
            <span className={styles.desktop}>Kontakt</span>
          </motion.button>
        ) : (
          <motion.button
            key="2"
            initial={{ opacity: 0, y: 10, rotate: '45deg' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10, rotate: '45deg' }}
            transition={{
              duration: 0.15
            }}
            className={styles.close}
            onClick={() => setIsMenuOpen(false)}
          >
            Stäng
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  </div>
)

export default Header
