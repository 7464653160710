const normalizeProjects = projects => {
  return projects.body.map(p => {
    return [
      ...p.items.map(item => ({
        category: 'image',
        ...item.image
      })),
      [...p.primary.description]
    ]
  })
}

export default normalizeProjects
