import React, { useState } from 'react'
import usePrismic from '../../utils/hooks/usePrismic'
import { AnimatePresence } from 'framer-motion'
import Header from '../Header/Header'
import Slideshow from '../Slideshow/Slideshow'
import Contacts from '../Contacts/Contacts'

const App = () => {
  const [loading, contactData] = usePrismic('contact')
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  if (loading) {
    return null
  }

  return (
    <div>
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <main>
        <Slideshow isMenuOpen={isMenuOpen} />
        <AnimatePresence>{isMenuOpen && <Contacts data={contactData} />}</AnimatePresence>
      </main>
    </div>
  )
}

export default App
