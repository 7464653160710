import React from 'react'
import styles from './NavButtons.module.scss'
import cn from 'classnames'

const NavButtons = ({ hidden, navigate }) => {
  return (
    <div className={styles.container}>
      <button
        onClick={() => navigate('up')}
        type="button"
        className={cn(styles.navButton, styles.up, { [styles.hidden]: hidden })}
      >
        Up
      </button>
      <button
        onClick={() => navigate('left')}
        type="button"
        className={cn(styles.navButton, styles.right, { [styles.hidden]: hidden })}
      >
        Right
      </button>
      <button
        onClick={() => navigate('right')}
        type="button"
        className={cn(styles.navButton, styles.left, { [styles.hidden]: hidden })}
      >
        Left
      </button>
      <button
        onClick={() => navigate('down')}
        type="button"
        className={cn(styles.navButton, styles.down, { [styles.hidden]: hidden })}
      >
        Down
      </button>
    </div>
  )
}

export default NavButtons
