import { useEffect, useState } from 'react'
import Prismic from 'prismic-javascript'

const apiEndpoint = process.env.REACT_APP_API_URL
const accessToken = process.env.REACT_APP_ACCESS_TOKEN

const Client = Prismic.client(apiEndpoint, { accessToken })

const usePrismic = endpoint => {
  const [doc, setDocData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at('document.type', endpoint)
      )
      if (response) {
        setDocData(response.results[0])
        setLoading(false)
      }
    }

    fetchData()
  }, [endpoint])

  if (doc && doc.data) {
    return [loading, doc.data]
  }

  return [loading, doc]
}

export default usePrismic
